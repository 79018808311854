import React, { useRef } from 'react';
import SlideItem from './SlideItem';
import TinySlider from 'tiny-slider-react';
import { useTranslation } from 'react-i18next';

// Import images directly
import bgImage from '../../assets/images/background/bg.jpg';

const settings = {
  container: '.my-slider-11',
  loop: true,
  lazyload: true,
  nav: false,
  mouseDrag: true,
  items: 1,
  autoplay: true,
  autoHeight: true,
  controls: true,
  controlsContainer: '.tns-controls',
  gutter: 0,
  autoplayButton: false,
  autoplayButtonOutput: false,
};

const Banner = () => {
  const listRef = useRef(null);
  const { t } = useTranslation();

  const dayRange = t('bannerSection.dayRange');
  const timeRange = t('bannerSection.timeRange');
  const socials = [
    {
      id: 1,
      href: 'https://www.linkedin.com/company/tendanz/',
      title: t('bannerSection.socials.linkedin'),
    },
  ];
  const banners = [
    {
      id: 1,
      bg: bgImage,
      bannerSubtitle: t('bannerSection.banners.0.bannerSubtitle'),
      bannerTitle: t('bannerSection.banners.0.bannerTitle'),
    },
  ];

  return (
    <section className="banner-section banner-one">
      <div className="left-based-text">
        <div className="base-inner">
          <div className="social-links">
            <ul className="clearfix">
              {socials.map(({ id, href, title }) => (
                <li key={id}>
                  <a href={href} target="_blank" rel="noopener noreferrer">
                    <span>{title}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="banner-carousel">
        <TinySlider settings={settings} ref={listRef}>
          {banners.map((slide) => (
            <SlideItem key={slide.id} slide={slide} ref={listRef} />
          ))}
        </TinySlider>
        <div className="tns-controls">
          <button className="tns-prev">
            <span className="icon fa fa-angle-left"></span>
          </button>
          <button className="tns-next">
            <span className="icon fa fa-angle-right"></span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Banner;
