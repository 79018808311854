import React from "react";
import Layout from "../components/Layout/Layout";
import Footer from "../components/Footer/Footer";
import Style from "../components/Reuseable/Style";
import HeaderTwo from "../components/Header/HeaderTwo";
import DataPolicyComponent from "../components/DataPolicy/DataPolicyComponent";

const DataPolicy= () => {
    return (
      <Layout pageTitle="Data Policy">
        <Style />
        <HeaderTwo />
        <DataPolicyComponent/>
        <Footer normalPadding={false} />
  
      </Layout>
    );
  };
  
  export default DataPolicy;
  