import React from 'react';
import { useTranslation } from 'react-i18next';

const CallToSectionTwo = ({
  className = "",
  btnClassName = "btn-style-two",
}) => {
  const { t } = useTranslation();

  return (
    <section className={`call-to-section-two ${className}`}>
      <div className="auto-container">
        <div className="inner clearfix">
          <h2>
            {t("callToSectionTwo.interestedTitle")}<br />{t("callToSectionTwo.talkTitle")} 
          </h2>
          <div className="link-box">
            <a href="mailto: contact@tendanz.com" className={`theme-btn ${btnClassName}`}>
              <i className="btn-curve"></i>
              <span className="btn-title">{t("callToSectionTwo.contactUs")}</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToSectionTwo;
