import React from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SingleNews from "./SingleNews";
import useActive from "../../hooks/useActive";

const NewsSection = ({ className = "", showTitle = true, isMore = false }) => {
  const { t } = useTranslation();
  const ref = useActive("#blogs");

  const newsData = t('newsSection.news', { returnObjects: true });

  return (
    <section ref={ref} className={`news-section ${className}`} id="blog">
      <div className="auto-container">
        {showTitle && (
          <div className="sec-title centered">
            <h2>
              {t('newsSection.title')}
              <span className="dot">.</span>
            </h2>
          </div>
        )}

        <Row className="clearfix">
          {newsData
            .slice(0, showTitle ? 3 : undefined)
            .map((news) => (
              <SingleNews key={news.id} news={news} />
            ))}
        </Row>
        {isMore && (
          <div className="more-box">
            <a href="/blog" className="theme-btn btn-style-one">
              <i className="btn-curve"></i>
              <span className="btn-title">{t('newsSection.loadMore')}</span>
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default NewsSection;
