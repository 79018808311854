import React from "react";
import { Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SingleNews = ({ news = {}, handleOpen }) => {
  const { image, date, admin, comments, title, text, showVideo } = news;
  const { t } = useTranslation();

  return (
    <Col lg={4} md={6} sm={12} className="news-block animated fadeInUp">
    <div className="inner-box">
        <div className="image-box">
            <a>
                <Image
                    src={require(`../../assets/images/resource/${image}`)}
                    alt=""
                />
            </a>
        </div>
        <div className="lower-box">
            <ul className="clearfix">
            </ul>
            <h5>
                {title}
            </h5>
            <div className="text">{text}</div>
        </div>
    </div>
</Col>

  );
};

export default SingleNews;
