import React from "react";
import Layout from "../components/Layout/Layout";
import Footer from "../components/Footer/Footer";
import Style from "../components/Reuseable/Style";
import HeaderTwo from "../components/Header/HeaderTwo";
import LegalNoticeComponent from "../components/LegalNotice/LegalNoticeComponent";

const LegalNotice= () => {
    return (
      <Layout pageTitle="Legal Notice">
        <Style />
        <HeaderTwo />
        <LegalNoticeComponent/>
        <Footer normalPadding={false} />
  
      </Layout>
    );
  };
  
  export default LegalNotice;
  