import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-bootstrap';
import NavItem from './NavItem';
import { useRootContext } from '../../context/context';
import useScroll from '../../hooks/useScroll';
import logo1 from '../../assets/images/logo-horis.png';
import logo2 from '../../assets/images/logo_tendanz-white.png';
import logo3 from '../../assets/images/logo_tendanz-blue.png';
import icon from '../../assets/images/icons/close-1-1.png';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { getPhoneNumber } from '../../utils/getPhoneNumber'; 


const navItems = [
  { id: 1, name: 'home', href: '/' },
  { id: 2, name: 'services', href: '/#services' },
  { id: 3, name: 'join', href: '/#join-us' },
  { id: 4, name: 'about', href: '/about-us' },
];

const Header = ({
  headerStyle = 'header-style-one',
  logo = 1,
  onePage = false,
  topBar = false,
  autoContainer = false,
  links = true,
  rightMenu = false,
}) => {
  const { t } = useTranslation();
  const { scrollTop } = useScroll(120);
  const { toggleMenu, toggleSearch } = useRootContext();
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const fetchPhoneNumber = async () => {
      const number = await getPhoneNumber();
      setPhoneNumber(number);
    };
    fetchPhoneNumber();
  }, []);
  const newNavItems = onePage ? navItems : navItems;

  let Logo =
    logo === 2
      ? logo2
      : logo === 3
      ? logo3
      : logo1;

  return (
    <header className={`main-header${scrollTop ? ' fixed-header' : ''} ${headerStyle}`}>
      {topBar && (
        <div className="topbar-four">
          <div className="auto-container">
            <p>{t('header.title2')}</p>
          </div>
        </div>
      )}
      <div className="header-upper">
        <div className={autoContainer ? 'inner-container clearfix' : ''}>
          <div className={autoContainer ? 'auto-container' : 'inner-container clearfix'}>
            <div className="logo-box">
              <div className="logo">
                <a href="/" title={t('header.title')}>
                  <Image id="thm-logo" src={Logo} alt={t('header.title')} title={t('header.title')} />
                </a>
              </div>
            </div>
            <div className="nav-outer clearfix">
              <div onClick={toggleMenu} className="mobile-nav-toggler">
                <span className="icon flaticon-menu-2"></span>
                <span className="txt">{t('header.menu')}</span>
              </div>
              <nav className="main-menu navbar-expand-md navbar-light">
                <div className={autoContainer ? '' : 'collapse navbar-collapse show clearfix'} id={autoContainer ? '' : 'navbarSupportedContent'}>
                  <ul className="navigation clearfix">
                    {newNavItems.map((navItem) => (
                      <NavItem navItem={{ ...navItem, name: t(`header.navItems.${navItem.name}`) }} key={navItem.id} onePage={onePage} />
                    ))}
                  </ul>
                </div>
              </nav>
            </div>
            {links && (
              <div className="other-links clearfix">
                <div className="phone-number">
                <span className="contact-message">{t('contact-us')}:</span> <span>{phoneNumber}</span>
                </div>
                <div className="link-box">
                  <LanguageSelector />
                </div>
              </div>
            )}
            {rightMenu && (
              <div className="right-menu">
                <div className="search-btn">
                  <button onClick={toggleSearch} type="button" className="theme-btn search-toggler">
                    <span className="flaticon-loupe"></span>
                  </button>
                </div>
                <div onClick={toggleMenu} className="mobile-nav-toggler">
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="txt">{t('header.menu')}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
