import React from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const languages = [
  { code: 'en', name: 'English' },
  { code: 'fr', name: 'Français' },
  { code: 'pt', name: 'Português' },
  { code: 'de', name: 'Deutsch' },
  { code: 'it', name: 'Italiano' },
  { code: 'es', name: 'Español' },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (e) => {
    const value = e.target.value;
    Cookies.set('lang', value);
    i18n.changeLanguage(value);
  };

  return (
    <div className="btn-container">
      <label htmlFor="language-select" className="visually-hidden">
        Select Language
      </label>
      <select
        id="language-select"
        className="form-select text-white dropdownlanguage"
        value={i18n.language}
        onChange={changeLanguage}
      >
        {languages.map(language => (
          <option key={language.code} value={language.code} className='dropdownlanguageitem'>
            {language.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;
