import React from 'react';
import { useTranslation } from 'react-i18next';

const DataPolicyComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="data-policy-container">
      <div className="policy-content">
        <h2>{t('dataPolicy.title')}</h2>
        <section>
          <h3>{t('dataPolicy.introduction.title')}</h3>
          <p>
            {t('dataPolicy.introduction.text1')} <a target="_blank" href="https://www.tendanz.com">https://www.tendanz.com</a> {t('dataPolicy.introduction.text2')}
          </p>
        </section>

        <section>
          <h3>{t('dataPolicy.dataCollection.title')}</h3>
          <h4>{t('dataPolicy.dataCollection.dataUsed.title')}</h4>
          <p>
            {t('dataPolicy.dataCollection.dataUsed.text')}
            <br />
            {t('dataPolicy.dataCollection.dataUsed.details')}
          </p>
          <h4>{t('dataPolicy.dataCollection.dataUsage.title')}</h4>
          <p>
            {t('dataPolicy.dataCollection.dataUsage.text')}
            <br />
            {t('dataPolicy.dataCollection.dataUsage.details')}
          </p>
        </section>

        <section>
          <h3>{t('dataPolicy.dataSharing.title')}</h3>
          <p>{t('dataPolicy.dataSharing.text')}</p>
        </section>

        <section>
          <h3>{t('dataPolicy.dataSecurity.title')}</h3>
          <p>{t('dataPolicy.dataSecurity.text')}</p>
        </section>

        <section>
          <h3>{t('dataPolicy.yourRights.title')}</h3>
          <p>{t('dataPolicy.yourRights.text1')}</p>
          <p>
            {t('dataPolicy.yourRights.text2')} <a target="_blank" href="mailto:contact@tendanz.com">contact@tendanz.com</a>.
          </p>
        </section>

        <section>
          <h3>{t('dataPolicy.cookies.title')}</h3>
          <h4>{t('dataPolicy.cookies.whatIsCookie.title')}</h4>
          <p>{t('dataPolicy.cookies.whatIsCookie.text')}</p>
          <h4>{t('dataPolicy.cookies.usage.title')}</h4>
          <p>{t('dataPolicy.cookies.usage.text1')}</p>
          <p>{t('dataPolicy.cookies.usage.text2')}</p>
          <h4>{t('dataPolicy.cookies.acceptance.title')}</h4>
          <p>
            {t('dataPolicy.cookies.acceptance.text1')}
            <br />
            {t('dataPolicy.cookies.acceptance.text2')} <a target="_blank" href="http://www.cnil.fr">http://www.cnil.fr</a>.
          </p>
        </section>

        <section>
          <h3>{t('dataPolicy.policyChanges.title')}</h3>
          <p>{t('dataPolicy.policyChanges.text')}</p>
        </section>

        <section>
          <h3>{t('dataPolicy.contact.title')}</h3>
          <p>
            {t('dataPolicy.contact.text')} <a target="_blank" href="mailto:contact@tendanz.com">contact@tendanz.com</a>.
          </p>
        </section>
      </div>
    </div>
  );
}

export default DataPolicyComponent;
