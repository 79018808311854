import React, { useState } from 'react';
import { useRootContext } from '../../context/context';
import { useLocation } from 'react-router-dom'; // You can use a similar hook from your routing library

const SubItem = ({ subItem = {} }) => {
  const [active, setActive] = useState(false);
  const { menuStatus } = useRootContext();
  const { subItems, href, name } = subItem;
  const location = useLocation();

  const handleActive = (e) => {
    e.preventDefault();
    setActive((prevActive) => !prevActive);
  };

  return (
    <li className={`${subItems?.length ? 'dropdown' : ''} ${location.pathname === href ? 'current' : ''}`}>
      <a href={href}>
        {name}
        {subItems?.length && (
          <div onClick={handleActive} className={`dropdown-btn${active ? ' open' : ''}`}>
            <span className="fa fa-angle-right"></span>
          </div>
        )}
      </a>
      <ul style={{ display: !menuStatus || active ? 'block' : 'none' }}>
        {subItems?.map((item) => (
          <li key={item.id}>
            <a href={item.href}>
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    </li>
  );
};

const NavItem = ({ navItem = {}, mobile = false, onePage = false }) => {
  const [active, setActive] = useState(false);
  const location = useLocation();
  const { menuStatus, toggleMenu, currentActive } = useRootContext();

  const { name, href, subNavItems = [] } = navItem;
  const subHref = subNavItems.map((item) => item.href);
  const current = !onePage ? location.pathname === href || subHref.includes(location.pathname) : currentActive === href;

  const handleActive = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setActive((prevActive) => !prevActive);
  };

  return (
    <li className={`dropdown${current ? ' current' : ''}`}>
      <a onClick={() => mobile && href.includes('#') && toggleMenu()} href={href}>
        {name}
        {subNavItems.length > 0 && (
          <div onClick={handleActive} className={`dropdown-btn${active ? ' open' : ''}`}>
            <span className="fa fa-angle-right"></span>
          </div>
        )}
      </a>
      {subNavItems.length > 0 && (
        <ul style={{ display: !menuStatus || active ? 'block' : 'none' }}>
          {subNavItems.map((subItem) => (
            <SubItem key={subItem.id} subItem={subItem} />
          ))}
        </ul>
      )}
    </li>
  );
};

export default NavItem;
