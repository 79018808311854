import React, { forwardRef } from 'react';
import TextSplit from '../Reuseable/TextSplit';
import { useTranslation } from 'react-i18next';

// Import images directly
import bgImage from '../../assets/images/background/bg.jpg';

const SlideItem = ({ slide = {} }, ref) => {
  const { t } = useTranslation();

  const bannerSubtitle = t('slideItem.bannerSubtitle');
  const bannerTitle = t('slideItem.bannerTitle');

  return (
    <div ref={ref} style={{ userSelect: 'none' }} className="slide-item">
      <div className="left-top-line"></div>
      <div className="right-bottom-curve"></div>
      <div className="right-top-curve"></div>
      <div className="auto-container">
        <div className="content-box">
          <div className="content">
            <div className="inner">
              <h1>
                <TextSplit text={bannerTitle} /> <span className="colored-dots">...</span>
              </h1>
              <div className="sub-title">{bannerSubtitle}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(SlideItem);
