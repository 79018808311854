import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useActive from '../../hooks/useActive';
import dynamic from 'react-dynamic';
import { getPhoneNumber } from '../../utils/getPhoneNumber'; // Ensure this path is correct

const MapBox = dynamic(() => import('./MapBox'));
const FormBox = dynamic(() => import('./FormBox'));

const ContactSection = ({ className = "", map = false, form = false }) => {
  const ref = useActive('#contact');
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const fetchPhoneNumber = async () => {
      const number = await getPhoneNumber();
      setPhoneNumber(number);
    };
    fetchPhoneNumber();
  }, []);

  const title = t('contactSection.title');
  const contacts = t('contactSection.contacts', { returnObjects: true });
  const inputs = t('contactSection.inputs', { returnObjects: true });

  return (
    <section ref={ref} className={`contact-section ${className}`} id="contact">
      <div className="auto-container">
        <div className="sec-title centered">
          <h2>
            {title} <span className="dot">.</span>
          </h2>
        </div>

        <div className="upper-info">
          <Row className="clearfix">
            {contacts.map(({ id, name, address, email }) => (
              <Col
                key={id}
                xl={12}
                lg={24}
                md={24}
                sm={48}
                className="info-block animated fadeInUp"
              >
                <div className="inner-box">
                  <h5>{name}</h5>
                  <div className="text">
                    <ul className="info">
                      <li>{address}</li>
                      <li>
                        <a href={`mailto:${email}`}>{email}</a>
                      </li>
                      <li>
                        <a href={`tel:${phoneNumber.replace(/\s+/g, '')}`}>{phoneNumber}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        {map && <MapBox />}
        {form && <FormBox inputs={inputs} />}
      </div>
    </section>
  );
};

export default ContactSection;
