import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setDefaultLanguage } from '../../utils/languageSetup';
import { getPhoneNumber } from '../../utils/getPhoneNumber';
import i18n from '../../utils/i18next';
import Cookies from 'js-cookie';

const CookieConsent = () => {
  const [consent, setConsent] = useState(Cookies.get('consent'));
  const { t } = useTranslation();

  useEffect(() => {
    if (consent === 'accepted') {
      setDefaultLanguage();
      getPhoneNumber();
    } else if (consent === 'declined') {
      Cookies.set('lang', 'en', { expires: 1 }); 
      i18n.changeLanguage('en');
      Cookies.set('phoneNumber', '(+33) 744 752 443', { expires: 1 }); 
    } else {
      i18n.changeLanguage('en');
      Cookies.set('phoneNumber', '(+33) 744 752 443', { expires: 1 }); 
    }
  }, [consent]);

  const handleAccept = () => {
    setConsent('accepted');
    Cookies.set('consent', 'accepted', { expires: 1 }); 
    setDefaultLanguage();
    getPhoneNumber();
  };

  const handleDecline = () => {
    setConsent('declined');
    Cookies.set('consent', 'declined', { expires: 1 }); 
    i18n.changeLanguage('en');
    Cookies.set('phoneNumber', '(+33) 744 752 443', { expires: 1 }); 
  };

  if (consent !== 'accepted' && consent !== 'declined') {
    return (
      <div className="cookie-consent">
        <p>
          {t('we-use-data')}
          <a href="/data-policy" target="_blank" rel="noopener noreferrer">{t('data-policy')}</a>
        </p>
        <button onClick={handleAccept}>{t('accept')}</button>
        <button onClick={handleDecline}>{t('decline')}</button>
      </div>
    );
  }

  return null; 
};

export default CookieConsent;
