import React from 'react';
import Layout from '../components/Layout/Layout';
import Style from '../components/Reuseable/Style';
import Header from '../components/Header/Header';
import MobileMenu from '../components/Header/MobileMenu';
import Banner from '../components/BannerSection/Banner';
import AboutSection from '../components/AboutSection/AboutSection';
import NewsSection from '../components/NewsSection/NewsSection';
import CallToSection from '../components/CallToSection/CallToSection';
import Footer from '../components/Footer/Footer';
import WeWorkSection from '../components/WeWorkSection/WeWorkSection';
import HeaderTwo from '../components/Header/HeaderTwo';

const Home = () => {
  return (
    <Layout pageTitle="Home">
      <Style />
      <Header />
      <MobileMenu />
      <Banner />
      <NewsSection />
      <WeWorkSection />
      <AboutSection />
      <CallToSection />
      <Footer normalPadding={false} />

    </Layout>
  );
};

export default Home;
