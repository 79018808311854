import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import '../src/utils/i18next'; 
import { setDefaultLanguage } from './utils/languageSetup';



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
