import React, { forwardRef } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const SingleService = ({ id, className = "", userSelect = false ,service = {}}, ref) => {
  const { t } = useTranslation();
  const testimonial = t(`services.single_service.items.${id - 1}`, { returnObjects: true });
  // const { text, name, image } = testimonial;
  const { text, name, image } = service;

  return (
    <div
      ref={ref}
      style={{ userSelect: userSelect ? "auto" : "none" }}
      className={`item ${className}`}
    >
      <div className="testimonials-four-card">
        <div className="testimonials-four-card__content">
          <div className="name">{name}</div>
          <p>{text}</p>
        </div>
        <div className="image">
          <Image
            src={require(`../../assets/images/resource/${image}`)}
            alt={name}
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(SingleService);
