import React from "react";
import Layout from "../components/Layout/Layout";
import Style from "../components/Reuseable/Style";
import HeaderTwo from "../components/Header/HeaderTwo";
import MobileMenu from "../components/Header/MobileMenu";
import ServiceSlider from "../components/ServiceSection/ServiceSlider";
import ServiceSliderTwo from "../components/ServiceSection/ServiceSlider2";
import ServiceSliderThree from "../components/ServiceSection/ServiceSlider3";
import CallToSection from "../components/CallToSection/CallToSection";
import Footer from "../components/Footer/Footer";

const Services = () => {
  return (
    <Layout pageTitle="Services">
      <Style />
      <HeaderTwo />
      <MobileMenu />
      <div className="service-section" id="claims-underwriting">
        <ServiceSlider />
      </div>
      <div className="service-section" id="risk-finance">
        <ServiceSliderTwo />
      </div>
      <div className="service-section" id="analytics-solutions">
        <ServiceSliderThree />
      </div>
      <CallToSection />
      <Footer normalPadding={false} />
    </Layout>
  );
};

export default Services;
