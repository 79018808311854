import React from "react";
import bg1 from "../../assets/images/logo_tendanz-white.png";

const Preloader = ({ loading, bg = bg1, className = "" }) => {
    return (
        <div
            style={{
                zIndex: loading ? 9999 : -1,
            }}
            className={`preloader animated${loading ? "" : " fadeOut"} ${className}`}
        >
            <div style={{ backgroundImage: `url(${bg})` }} className="icon"></div>
        </div>
    );
};

export default Preloader;
