// utils/getPhoneNumber.js
import Cookies from 'js-cookie';
import { fetchUserLocation } from './fetchUserLocation';

export const getPhoneNumber = async () => {
  const consent = Cookies.get('consent');

  if (consent === 'accepted') {
    const locationData = await fetchUserLocation();
    if (locationData && locationData.country === 'PT') {
      Cookies.set('phoneNumber', '(+351) 910 367 288');
      return '(+351) 910 367 288';
    }
  }

  Cookies.set('phoneNumber', '(+33) 744 752 443');
  return '(+33) 744 752 443';
};
