import React from 'react';
import { Row } from 'react-bootstrap';
import SingleReason from './SingleReason';
import useActive from '../../hooks/useActive';
import { useTranslation } from 'react-i18next';

const BePart = ({ className = '' }) => {
  const ref = useActive('#bePart');
  const { t } = useTranslation();

  const title = t('bePart.title');
  const who = t('bePart.who', { returnObjects: true });

  return (
    <section
      ref={ref}
      className={`services-section-three ${className} blank-space`}
      id="services"
    >
      <div className="auto-container">
        <div className="sec-title centered">
          <h2>
            {title} <span className="dot">.</span>
          </h2>
        </div>
        <div className="services">
          <Row className="clearfix">
            {who.map((service, index) => (
              <SingleReason service={service} key={index} />
            ))}
          </Row>
        </div>
      </div>
    </section>
  );
};

export default BePart;
