import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import TextSplit from '../Reuseable/TextSplit';
import useActive from '../../hooks/useActive';
import { useTranslation } from 'react-i18next';

// Import images directly
import image1 from '../../assets/images/resource/about-us-mock1.jpg';
import image2 from '../../assets/images/resource/about-us-mock2.jpg';

const AboutSection = () => {
  const ref = useActive("#about");
  const { t } = useTranslation();

  const title = t('aboutSection.title');
  const lowerText = t('aboutSection.lowerText');
  const textList = t('aboutSection.textList', { returnObjects: true });
  const readMore = t('aboutSection.readMore');

  return (
    <section ref={ref} className="about-section" id="join-us">
      <div className="auto-container">
        <Row className="clearfix">
          <Col xl={6} lg={12} md={12} sm={12} className="image-column">
            <div className="inner">
              <div className="image-block animated fadeInLeft">
                <Image src={image1} alt="" />
              </div>
              <div className="image-block animated fadeInUp">
                <Image src={image2} alt="" />
              </div>
            </div>
          </Col>
          <Col xl={6} lg={12} md={12} sm={12} className="text-column">
            <div className="inner">
              <div className="sec-title">
                <h2>
                  <TextSplit text={title} />
                  <span className="dot">.</span>
                </h2>
                <div className="lower-text">
                  {lowerText}
                </div>
              </div>
              <div className="text clearfix">
                <ul>
                  {textList.map((text, i) => (
                    <li key={i}>{text}</li>
                  ))}
                </ul>
              </div>
              <a href="/join-us">
                {readMore} &#62;
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default AboutSection;
