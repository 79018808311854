import React, { useRef, Suspense } from "react";
import SingleService from "./SingleService";
import { useTranslation } from "react-i18next";

const TinySlider = React.lazy(() => import("../../components/TinySlider/TinySlider"));

const settings = {
  container: ".my-slider-23",
  loop: false,
  lazyload: true,
  nav: true,
  navPosition: "bottom",
  mouseDrag: true,
  items: 1,
  autoplay: true,
  autoHeight: true,
  controls: false,
  gutter: 0,
  autoplayButton: false,
  autoplayButtonOutput: false,
  responsive: {
    600: {
      items: 1,
      gutter: 30,
    },
    768: {
      items: 2,
      gutter: 30,
    },
    1200: {
      items: 3,
      gutter: 30,
    },
  },
};

const ServiceSliderTwo = () => {
  const { t } = useTranslation();
  const listRef = useRef(null);

  const servicesData = t("services.risk_finance", { returnObjects: true });

  return (
    <section className="testimonials-four grey-bg" id="risk-finance">
      <div className="auto-container">
        <div className="testimonials-four__block__top">
          <div className="sec-title">
            <h2>
              {servicesData.title}
              <span className="dot">.</span>
            </h2>
          </div>
        </div>
        <div className="testimonials-four-carousel position-relative">
          <Suspense fallback={<div>Loading...</div>}>
            <TinySlider options={settings} ref={listRef}>
              {servicesData.items.map((item) => (
                <SingleService ref={listRef} key={item.id} service={item} />
              ))}
            </TinySlider>
          </Suspense>
        </div>
      </div>
    </section>
  );
};

export default ServiceSliderTwo;
