import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import images directly
import servicesmock1 from '../../assets/images/resource/servicesmock1.png';
import servicesmock2 from '../../assets/images/resource/servicesmock2.png';
import servicesmock3 from '../../assets/images/resource/servicesmock3.png';

const SingleWeWork = ({ work = {}, current }) => {
  const { id, image, text1, text2, lists } = work;
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Map the imported images to the `image` prop
  const imageMap = {
    'servicesmock1.png': servicesmock1,
    'servicesmock2.png': servicesmock2,
    'servicesmock3.png': servicesmock3,
  };

  const handleLearnMoreClick = () => {
    navigate(`/services#${text2}`);

    // Ensure the navigation is complete before scrolling
    setTimeout(() => {
      const element = document.getElementById(text2);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        // If the element is not found, try again after a short delay
        setTimeout(() => {
          const retryElement = document.getElementById(text2);
          if (retryElement) {
            retryElement.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      }
    }, 100);
  };

  return (
    <div className={`tab animated${current === id ? " active-tab fadeInUp" : ""}`} id={id}>
      <Row className="clearfix">
        <Col lg={5} md={6} sm={12} className="image-col">
          <div className="inner">
            <div className="image">
              <Image src={imageMap[image]} alt="" />
            </div>
          </div>
        </Col>
        <Col lg={7} md={6} sm={12} className="text-col">
          <div className="inner">
            <div className="content">
              <div className="text">
                <p>{t(text1)}</p>
                <ul>
                  {lists.map((text, i) => (
                    <li key={i + text}>{t(text)}</li>
                  ))}
                </ul>
              </div>
            </div>
            <li>
              <span onClick={handleLearnMoreClick} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                {t("learnMore")} &#62;
              </span>
            </li>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SingleWeWork;
