import axios from 'axios';

export const fetchUserLocation = async () => {
  try {
    const response = await axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=58d5727cd6a24a889b94d4d0c4921547');
    return response.data;
  } catch (error) {
    return null;
  }
};
