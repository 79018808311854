import React from 'react';
import Layout from '../components/Layout/Layout';
import Style from '../components/Reuseable/Style';
import HeaderTwo from '../components/Header/HeaderTwo';
import MobileMenu from '../components/Header/MobileMenu';
import BePart from '../components/BePart/BePart';
import CallToSectionTwo from '../components/CallToSection/CallToSectionTwo';
import Footer from '../components/Footer/Footer';

const JoinUs = () => {
  return (
    <Layout pageTitle="Join Us">
      <Style />
      <HeaderTwo />
      <MobileMenu />
      <BePart />
      <CallToSectionTwo />
      <Footer normalPadding={false} />

    </Layout>
  );
};

export default JoinUs;
