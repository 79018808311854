import React, { useState } from "react";
import SingleWeWork from "./SingleWeWork";
import { useTranslation } from "react-i18next";

// Embedded data
const weWorkSectionData = {
  tabBtns: [
    {
      id: "tab-1",
      title: "claimsUnderwriting", // Key for translation
    },
    {
      id: "tab-2",
      title: "riskFinance", // Key for translation
    },
    {
      id: "tab-3",
      title: "analyticsSolutions", // Key for translation
    },
  ],
  tabsContents: [
    {
      id: "tab-1",
      image: "servicesmock1.png",
      text1: "claimsUnderwritingText", // Key for translation
      text2: "claims-underwriting",
      lists: [
        "product",
        "pricing",
        "portfolioMonitoring",
        "mna",
        "brokerage",
      ],
    },
    {
      id: "tab-2",
      image: "servicesmock2.png",
      text1: "riskFinanceText", // Key for translation
      text2: "risk-finance",
      lists: [
        "quantitativeRiskManagement",
        "qualitativeRiskManagement",
        "controlling",
        "ifrs17",
        "reporting",
        "automation",
      ],
    },
    {
      id: "tab-3",
      image: "servicesmock3.png",
      text1: "analyticsSolutionsText", // Key for translation
      text2: "analytics-solutions",
      lists: [
        "dataGovernance",
        "dataArchitecture",
        "modeling",
        "visualization",
        "automation",
      ],
    },
  ],
};

const WeWorkSection = () => {
  const [current, setCurrent] = useState("tab-1");
  const { t } = useTranslation();
  const { tabBtns, tabsContents } = weWorkSectionData;

  return (
    <section className="we-work-section" id="services">
      <div className="auto-container">
        <div className="sec-title centered">
          <h2>
            {t("weSupportYou")}
            <span className="dot">.</span>
          </h2>
        </div>
        <div className="work-tabs tabs-box">
          <ul className="tab-btns tab-buttons clearfix">
            {tabBtns.map(({ id, title }) => (
              <li
                onClick={() => setCurrent(id)}
                key={id}
                className={`tab-btn${current === id ? " active-btn" : ""}`}
              >
                <span>{t(title)}</span>
              </li>
            ))}
          </ul>
          <div className="tabs-content">
            {tabsContents.map((work) => (
              <SingleWeWork key={work.id} work={work} current={current} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeWorkSection;
