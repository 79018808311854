import React from 'react';
import Layout from '../components/Layout/Layout';
import Style from '../components/Reuseable/Style';
import HeaderTwo from '../components/Header/HeaderTwo';
import MobileMenu from '../components/Header/MobileMenu';
import BusinessInfoSection from '../components/BusinessInfoSection/BusinessInfoSection';
import ContactSection from '../components/ContactSection/ContactSection';
import CallToSectionTwo from '../components/CallToSection/CallToSectionTwo';
import Footer from '../components/Footer/Footer';

const AboutUs = () => {
  return (
    <Layout pageTitle="About Us">
      <Style />
      <HeaderTwo />
      <MobileMenu />
      <BusinessInfoSection />
      {/* <TeamSection /> */}
      <ContactSection map />
      <CallToSectionTwo className="alternate" />
      <Footer normalPadding={false} />

    </Layout>
  );
};

export default AboutUs;
