import { fetchUserLocation } from './fetchUserLocation';
import i18n from './i18next';
import Cookies from 'js-cookie';


const supportedLanguages = ['fr', 'pt', 'de', 'it', 'es', 'en'];

export const setDefaultLanguage = async () => {
  const savedLanguage = Cookies.get('lang');

  if (savedLanguage && supportedLanguages.includes(savedLanguage)) {
    i18n.changeLanguage(savedLanguage);
    return;
  }

  try {
    const locationData = await fetchUserLocation();

    if (locationData && locationData.languages) {
      const languages = locationData.languages.split(',').map(lang => lang.trim());

      let language = 'en'; // Default language

      for (const lang of languages) {
        const primaryLanguage = lang.split('-')[0];
        if (supportedLanguages.includes(primaryLanguage)) {
          language = primaryLanguage;
          break;
        }
      }

      Cookies.set('lang', language, { expires: 1 });
      i18n.changeLanguage(language);
    } else {
      Cookies.set('lang', 'en', { expires: 1 });
      i18n.changeLanguage('en');
    }
  } catch (error) {
    Cookies.set('lang', 'en', { expires: 1 });
    i18n.changeLanguage('en');
  }
};
