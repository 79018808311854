import React from 'react';
import { useTranslation } from 'react-i18next';

const CallToSection = ({ className = "", container = true }) => {
  const { t } = useTranslation();

  return (
    <section className={`call-to-section ${className}`}>
      <div className={container ? "auto-container" : "inner clearfix"}>
        <div className={container ? "inner clearfix" : ""}>
          <div className="shape-1 animated slideInRight"></div>
          <div className="shape-2 animated fadeInDown"></div>
          <div className={container ? "" : "auto-container clearfix"}>
            <h2>
              {t("callToSection.title")} {container ? <br /> : ""}
            </h2>
            <div className="link-box">
              <a href="mailto: contact@tendanz.com" className="theme-btn btn-style-two">
                <i className="btn-curve"></i>
                <span className="btn-title">{t("callToSection.contactUs")}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToSection;
