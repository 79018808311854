import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import context from './context';

const ContextProvider = ({ children }) => {
  const [menuStatus, setMenuStatus] = useState(false);
  const [currentActive, setCurrentActive] = useState("#home");

  const location = useLocation();

  const toggleMenu = (value) => {
    setMenuStatus((prevMenuStatus) => (typeof value === "boolean" ? value : !prevMenuStatus));
  };

  useEffect(() => {
    toggleMenu(false);
  }, [location.pathname]);

  const Value = {
    menuStatus,
    toggleMenu,
    currentActive,
    setCurrentActive,
  };

  return <context.Provider value={Value}>{children}</context.Provider>;
};

export default ContextProvider;
