import React, { useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import TextSplit from "../Reuseable/TextSplit";
import { useTranslation } from "react-i18next";

// Import the image directly
import image from "../../assets/images/resource/learnmore.png";

const BusinessInfoSection = () => {
  const [current, setCurrent] = useState("tab-1");
  const { t } = useTranslation();

  const title = t("businessInfoSection.title");
  const text = t("businessInfoSection.text");
  const tabBtns = [
    {
      id: "tab-1",
      text: t("businessInfoSection.tabBtns.mission")
    },
    {
      id: "tab-2",
      text: t("businessInfoSection.tabBtns.vision")
    },
    {
      id: "tab-3",
      text: t("businessInfoSection.tabBtns.values")
    }
  ];
  const tabContents = [
    {
      id: "tab-1",
      text: t("businessInfoSection.tabContents.mission.text"),
      text2: "",
      text3: ""
    },
    {
      id: "tab-2",
      text: t("businessInfoSection.tabContents.vision.text"),
      text2: "",
      text3: ""
    },
    {
      id: "tab-3",
      text: t("businessInfoSection.tabContents.values.text"),
      text2: t("businessInfoSection.tabContents.values.text2"),
      text3: t("businessInfoSection.tabContents.values.text3")
    }
  ];

  return (
    <section className="agency-section">
      <div className="auto-container">
        <Row className="clearfix">
          <Col xl={6} lg={12} md={12} sm={12} className="left-col">
            <div className="inner">
              <div className="sec-title">
                <h2>
                  <TextSplit text={title} /> <span className="dot">.</span>
                </h2>
              </div>
              <div className="default-tabs tabs-box">
                <ul className="tab-btns tab-buttons clearfix">
                  {tabBtns.map(({ id, text }) => (
                    <li
                      onClick={() => setCurrent(id)}
                      key={id}
                      className={`tab-btn${current === id ? " active-btn" : ""}`}
                    >
                      <span>{text}</span>
                    </li>
                  ))}
                </ul>
                <div className="tabs-content">
                  {tabContents.map(({ id, text, text2, text3 }) => (
                    <div
                      key={id}
                      className={`tab animated${current === id ? " active-tab fadeInUp" : ""}`}
                      id={id}
                    >
                      <div className="content">
                        <div className="text">{text}</div>
                        {text2 && <div className="text">{text2}</div>}
                        {text3 && <div className="text">{text3}</div>}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Col>
          <Col xl={6} lg={12} md={12} sm={12} className="right-col">
            <div className="inner">
              <div className="text">{text}</div>
              <div className="featured-block-two clearfix">
                <div className="image">
                  <Image src={image} alt="" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default BusinessInfoSection;
