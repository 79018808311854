import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import TextSplit from '../Reuseable/TextSplit';
import logo from "../../assets/images/logo-horis.png";
import { useTranslation } from 'react-i18next';
import { getPhoneNumber } from '../../utils/getPhoneNumber';

const Footer = ({ normalPadding = true }) => {

  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState('');
  useEffect(() => {
    const fetchPhoneNumber = async () => {
      const number = await getPhoneNumber();
      setPhoneNumber(number);
    };
    fetchPhoneNumber();
  }, []);

  const data = {
    year: new Date().getFullYear(),
    author: t('footer.author'),
    logo: logo,
    text: t('footer.text'),
    address: t('footer.address'),
    phone: phoneNumber,
    email: t('footer.email'),
    textBottom: t('footer.textBottom'),
    explore: t('footer.explore'),
    newsletter: t('footer.newsletter'),
    newsletterPlaceholder: t('footer.newsletterPlaceholder'),
    copyright: t('footer.copyright'),
    by: t('footer.by'),
    socials: [
      {
        id: 1,
        href: "https://www.linkedin.com/company/tendanz/",
        icon: "fab fa-linkedin",
      },
    ],
    links: [
      {
        id: 1,
        href: "/about-us",
        title: t('footer.links.about'),
      },
      {
        id: 2,
        href: "/services",
        title: t('footer.links.services'),
      },
      {
        id: 3,
        href: "/join-us",
        title: t('footer.links.careers'),
      },
      {
        id: 4,
        href: "/data-policy",
        title: t('data-policy'),
      },
      {
        id:5,
        href: "/legal-notice",
        title: t('legalNotice.title'),
      }
      
    ],
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    console.log(formData.get("email"));
  };

  const firstHalf = data.links.slice(0, 3);
  const secondHalf = data.links.slice(3);

  return (
    <footer className={`main-footer${normalPadding ? " normal-padding" : ""}`}>
      <div className="auto-container">
        <div className="widgets-section">
          <Row className="clearfix">
            <Col xl={3} lg={6} md={6} sm={12} className="column">
              <div className="footer-widget logo-widget">
                <div className="widget-content">
                  <div className="logo">
                    <a href="/">
                      <Image id="fLogo" src={data.logo} alt="Logo" />
                    </a>
                  </div>
                  <div className="text">{data.text}</div>
                  <ul className="social-links clearfix">
                    {data.socials.map(({ id, icon, href }) => (
                      <li key={id}>
                        <a href={href} target="_blank" rel="noopener noreferrer">
                          <span className={icon}></span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={6} md={6} sm={12} className="column">
      <div className="footer-widget links-widget">
        <div className="widget-content">
          <h6>{data.explore}</h6>
          <Row className="clearfix">
            <Col md={6} sm={12}>
              <ul>
                {firstHalf.map(({ id, href, title }) => (
                  <li key={id}>
                    <a href={href}>{title}</a>
                  </li>
                ))}
              </ul>
            </Col>
            <Col md={6} sm={12}>
              <ul>
                {secondHalf.map(({ id, href, title }) => (
                  <li key={id}>
                    <a href={href}>{title}</a>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
            <Col xl={3} lg={6} md={6} sm={12} className="column">
              <div className="footer-widget info-widget">
                <div className="widget-content">
                  <h6>{data.contact}</h6>
                  <ul className="contact-info">
                    <li className="address">
                      <span className="icon flaticon-pin-1"></span>{" "}
                      <TextSplit text={data.address} />
                    </li>
                    <li>
                      <span className="icon flaticon-call"></span>
                      <a href={`tel:${data.phone.replace(/\s+/g, '')}`}>{data.phone}</a>
                    </li>
                    <li>
                      <span className="icon flaticon-email-2"></span>
                      <a href={`mailto:${data.email}`}>{data.email}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={6} md={6} sm={12} className="column">
              <div className="footer-widget newsletter-widget">
                <div className="widget-content">
                  <h6>{data.newsletter}</h6>
                  <div className="newsletter-form">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group clearfix">
                        <input
                          type="email"
                          name="email"
                          placeholder={data.newsletterPlaceholder}
                          required
                        />
                        <button type="submit" className="theme-btn">
                          <span className="fa fa-envelope"></span>
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="text">{data.textBottom}</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="auto-container">
          <div className="inner clearfix">
            <div className="copyright">
              &copy; {data.copyright} {data.year} {data.by} {data.author}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
