import React from 'react';
import { useTranslation } from 'react-i18next';

const LegalNoticeComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="data-policy-container">
      <div className="policy-content">
        <h2>{t('legalNotice.title')}</h2>
        
        <section>
          <h3>{t('legalNotice.publisher.title')}</h3>
          <p>
            "Tendanz Group" {t('legalNotice.publisher.description')}
            <br/>{t('legalNotice.publisher.legalEntities1')} <a href="https://www.tendanz.com" target="_blank"> https://www.tendanz.com </a> {t('legalNotice.publisher.legalEntities2')}
            <br/>Tendanz SA, {t('legalNotice.publisher.companyDetails')}
            {t('legalNotice.publisher.headOffice')}: 66 Avenue des Champs Elysées 75008 Paris.
            {t('legalNotice.publisher.mainFunction')}:<a href="https://www.tendanz.com" target="_blank"> https://www.tendanz.com </a>{t('legalNotice.publisher.mainFunction2')}
            <br />
            
            {t('legalNotice.publisher.publicationDirector')}
          </p>
        </section>

        <section>
          <h3>{t('legalNotice.host.title')}</h3>
          <p>
            {t('legalNotice.host.description')} <a href="https://www.tendanz.com" target="_blank"> https://www.tendanz.com </a>{t('legalNotice.host.description2')}
            <br />
            {t('legalNotice.host.phone')}: +41 55 220 63 10
            <br />
            {t('legalNotice.host.email')}: <a href="mailto:privacy@hostpoint.ch" target="_blank">privacy@hostpoint.ch</a>
            <br />
            {t('legalNotice.host.website')}: <a href="https://www.hostpoint.ch" target="_blank">https://www.hostpoint.ch</a>
          </p>
        </section>

        <section>
          <h3>{t('legalNotice.generalInfo.title')}</h3>
          <p>
            {t('legalNotice.generalInfo.description')} <a href="https://www.tendanz.com" target="_blank"> https://www.tendanz.com </a>{t('legalNotice.generalInfo.description2')}
            <br />
            {t('legalNotice.generalInfo.accuracyDisclaimer')}
            <br />
            {t('legalNotice.generalInfo.availabilityDisclaimer1')}
            <br />
            {t('legalNotice.generalInfo.availabilityDisclaimer2')}
            <br />
            {t('legalNotice.generalInfo.availabilityDisclaimer3')}
          </p>
        </section>

        <section>
          <h3>{t('legalNotice.siteAccess.title')}</h3>
          <p>{t('legalNotice.siteAccess.description')}</p>
        </section>

        <section>
          <h3>{t('legalNotice.intellectualProperty.title')}</h3>
          <p>
            {t('legalNotice.intellectualProperty.description1')}
            <br />
            {t('legalNotice.intellectualProperty.description2')}
<br/>
{t('legalNotice.intellectualProperty.description3')}
<br/>
            {t('legalNotice.intellectualProperty.logos')}
            <br />
            {t('legalNotice.intellectualProperty.copyright')}
            <br />
          </p>
        </section>

        <section>
          <h3>{t('legalNotice.hyperlinksCookies.title')}</h3>
          <p>
            {t('legalNotice.hyperlinksCookies.hyperlinks')}
            <br />
            {t('legalNotice.hyperlinksCookies.deepLinks')}
            <br />
            {t('legalNotice.hyperlinksCookies.externalLinks')} <a href="https://www.tendanz.com" target="_blank"> https://www.tendanz.com </a>{t('legalNotice.hyperlinksCookies.externalLinks2')} 
            <br />
            {t('legalNotice.hyperlinksCookies.rgpd')} <a href="https://www.tendanz.com" target="_blank"> https://www.tendanz.com </a>{t('legalNotice.hyperlinksCookies.rgpd2')} 
            <a href="https://www.tendanz.com/data-policy" target="_blank"> https://www.tendanz.com/data-policy </a>
            <br/>
            {t('legalNotice.hyperlinksCookies.desc1')}
            <br/>
            {t('legalNotice.hyperlinksCookies.desc2')}
          </p>
        </section>

        <section>
          <h3>{t('legalNotice.lawJurisdiction.title')}</h3>
          <p>
            {t('legalNotice.lawJurisdiction.description1')}
            <br />{t('legalNotice.lawJurisdiction.description2')}
            <br />
          </p>
        </section>

        <section>
          <h3>{t('legalNotice.modifications.title')}</h3>
          <p>{t('legalNotice.modifications.description')}</p>
        </section>
      </div>
    </div>
  );
}

export default LegalNoticeComponent;
